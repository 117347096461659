import React, { useState, useEffect } from 'react';
import { followSeller, unfollowSeller, getFollowingStatus } from '../../../../api/followApi';
import styled from 'styled-components';

interface ShopBannerProps {
  seller: any;
  userId: string;
}

const FollowButton = styled.button<{ isFollowed: boolean, disabled: boolean }>`
  background-color: ${({ isFollowed }) => (isFollowed ? '#ffa41c' : '#f0c14b')};
  border: 1px solid ${({ isFollowed }) => (isFollowed ? '#ffa41c' : '#a88734 #9c7e31 #846a29')};
  color: #111;
  padding: 7px 20px;
  font-size: 14px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
  border-radius: 3px;
  text-align: center;
  &:hover {
    background-color: ${({ isFollowed }) => (isFollowed ? '#e78a15' : '#ddb347')};
  }
`;

const ErrorMessage = styled.div`
  color: red;
  margin-top: 10px;
`;

export default function ShopBanner({ seller, userId }: ShopBannerProps): JSX.Element {
  const [isFollowing, setIsFollowing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    console.log('ShopBanner useEffect triggered');
    if (userId && seller?._id) {
      checkFollowStatus();
    }
  }, [userId, seller]);

  const checkFollowStatus = async () => {
    if (!userId || !seller?._id) {
      console.log('Missing userId or seller._id');
      return;
    }
    try {
      console.log('Checking follow status for userId:', userId, 'and sellerId:', seller._id);
      const followingList = await getFollowingStatus(userId);
      console.log('Received followingList:', followingList);
      const isCurrentSellerFollowed = followingList.some((followedSeller: any) => followedSeller.sellerId._id === seller._id);
      console.log('Is current seller followed:', isCurrentSellerFollowed);
      setIsFollowing(isCurrentSellerFollowed);
    } catch (error) {
      console.error('Error checking follow status:', error);
      setError('Failed to check follow status');
    }
  };

  const handleFollowClick = async () => {
    if (!userId || !seller?._id) {
      setError('Invalid user or seller information');
      return;
    }
    setIsLoading(true);
    setError('');
    try {
      if (isFollowing) {
        await unfollowSeller(userId, seller._id);
        setIsFollowing(false);
      } else {
        await followSeller(userId, seller._id);
        setIsFollowing(true);
      }
      localStorage.setItem(`followStatus_${userId}_${seller._id}`, JSON.stringify(!isFollowing));
    } catch (error) {
      console.error('Error updating follow status:', error);
      if (error.response?.data?.details === "User is already following this seller") {
        setIsFollowing(true);
      } else {
        setError('Failed to update follow status. Please try again.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const renderContent = () => {
    if (!seller || !seller.sellerId) {
      return <div>No seller information available</div>;
    }

    const sellerId = seller.sellerId;
    const Seller_Shop_Name = seller?.shopName || seller?.firstName || "";

    if (sellerId === "29658816") {
      return (
        <div className="shopBannerImage">
          <img
            style={{
              width: "100%",
              height: "auto",
              maxHeight: "400px",
              objectFit: "fill",
            }}
            src="https://storage.googleapis.com/content.paysfer.com/banner/anyco_banner.webp"
            alt="Seller Image"
          />
        </div>
      );
    } else if (sellerId === "40551232") {
      return (
        <div className="shopBannerImage">
          <img
            style={{
              width: "100%",
              height: "auto",
              maxHeight: "350px",
              objectFit: "fill",
            }}
            src="https://storage.googleapis.com/content.paysfer.com/banner/updated_banners/RJ_BULLION_BANNER.jpg"
            alt="Seller Image"
          />
        </div>
      );
    } else if (sellerId === "81418180") {
      return (
        <div className="shopBannerImage">
          <img
            style={{
              width: "100%",
              height: "auto",
              maxHeight: "400px",
              objectFit: "fill",
            }}
            src="https://storage.googleapis.com/content.paysfer.com/banner/Kalustyans_Banner.webp"
            alt="Seller Image"
          />
        </div>
      );
    } else {
      return (
        <div className="shopDetailsHeader">
          <h3>{Seller_Shop_Name.toUpperCase()}</h3>
        </div>
      );
    }
  };

  return (
    <div className="shopBannerContainer">
      {renderContent()}
      {userId && seller && seller.sellerId && (
        <FollowButton 
          onClick={handleFollowClick} 
          isFollowed={isFollowing}
          disabled={isLoading}
        >
          {isLoading ? 'Loading...' : (isFollowing ? 'Followed' : 'Follow')}
        </FollowButton>
      )}
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </div>
  );
}
